import React, { useState } from "react";
import GoogleMapReact from "google-map-react";
import Marker from "../marker/marker";

const Map = (props: any) => {
  const lat = 54.17448779772151;
  const lng = -8.46133507578215;

  const [center, setCenter] = useState({ lat: lat, lng: lng });
  const [zoom, setZoom] = useState(15);

  return (
    <div style={{ height: "100vh", width: "100%" }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: "AIzaSyBtKzRj5idlK0YoK8GMr5YtQGRwJ9nWwIs" }}
        defaultCenter={center}
        defaultZoom={zoom}
        options={{
          styles: require(`../../assets/json/map-style-blue.json`),
        }}
      >
        <Marker lat={lat} lng={lng} name="My Marker" color="blue" />
      </GoogleMapReact>
    </div>
  );
};

export default Map;
