import * as React from "react";
import { theme } from "../../theme";
import { ThemeProvider } from "@emotion/react";
import {
  Box,
  Button,
  Container,
  Grid,
  Link,
  TextField,
  Typography,
} from "@mui/material";
import { Copyright } from "@mui/icons-material";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import styled from "styled-components";
import FavoriteIcon from "@mui/icons-material/Favorite";

const Footer = () => {
  const FooterContainer = styled.div`
    text-align: center;
    flex-grow: 1;
  `;

  return (
    <Typography
      component="footer"
      sx={{
        display: "flex",
        flexDirection: "column",
        bgcolor: "#d3d3d3",
        paddingTop: "25px",
        paddingBottom: "25px",
      }}
    >
      {/* <FooterContainer style={{ marginBottom: "20px" }}>
        <Button
          variant="contained"
          color="secondary"
          href="sms://+447401811173"
        >
          Any queries please give us a shout!
        </Button>
      </FooterContainer> */}
      <FooterContainer>
        <a
          href="https://www.instagram.com/markreecastlesligo/"
          target="_blank"
          style={{ marginRight: "20px" }}
        >
          <InstagramIcon
            style={{
              color: theme.palette.text.primary,
              fontSize: "35px",
            }}
          />
        </a>

        <a href="https://www.facebook.com/markreecastle/" target="_blank">
          <FacebookIcon
            style={{
              color: theme.palette.text.primary,
              fontSize: "35px",
            }}
          />
        </a>
      </FooterContainer>
    </Typography>
  );
};
export default Footer;
