import "./App.css";
import { ThemeProvider } from "@mui/material";
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";

import Login from "./pages/login/login";
import Admin from "./pages/admin/admin";
import RSVP from "./pages/rsvp/rsvp";
import { theme } from "./theme";
import Home from "./pages/home/home";
import AddGuest from "./pages/admin/addGuest/addGuest";
import QRCodes from "./pages/admin/qrCodes/qrCodes";
import Stats from "./pages/admin/stats/stats";

function App() {
  return (
    <div className="app">
      <ThemeProvider theme={theme}>
        <Router>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="*" element={<Navigate to="/" />} />

            {/* <Route path="/login" element={<Login />} />
            <Route path="/rsvp/:id" element={<RSVP />}></Route> */}
            {/* <Route path="/register" element={<Register />} /> */}
            {/* <Route path="/reset" element={<ResetPassword />} /> */}
            {/* <Route path="/admin" element={<Admin />} />
            <Route path="/add-guest" element={<AddGuest />} />
            <Route path="/qr-codes" element={<QRCodes />} />
            <Route path="/stats" element={<Stats />} /> */}
            {/* <Route path="/guest-list" element={<Stats />} /> */}
          </Routes>
        </Router>
      </ThemeProvider>
    </div>
  );
}

export default App;
