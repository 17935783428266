import React from "react";
import "./marker.css";

const Marker = (props: any) => {
  const { color, name, id } = props;
  const heartMarker = require("../../assets/img/icons/heart.png");
  const markerClicked = () => {
    window.location.href =
      "https://www.google.com/maps/place/Markree+Castle/@54.1743623,-8.4613887,15z/data=!4m2!3m1!1s0x0:0x6572031f912fe0cb?sa=X&ved=2ahUKEwiciY2WzOyCAxXONOwKHcm_C-MQ_BJ6BAhbEAA";
  };
  return (
    <div
      id="heart"
      className="marker"
      style={{
        cursor: "pointer",
        background: `url(${heartMarker})`,
      }}
      title={name}
      onClick={markerClicked}
    />
  );
};

export default Marker;
